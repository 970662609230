import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomTextField } from '../../styles/CustomTextField';
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from '../../styles/theme';
import { Button, MenuItem } from "@mui/material";
import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { InputResources } from "../../Integration/models";


interface SelectChangeEvent<T> {
    target: {
        value: T;
    };
}

export interface props {

    taskType: string;
    index: number;
    unit1: string;
    updateFuelData: (index: number, value: string, unit: string, fuelType: string, edit: boolean) => boolean;
    value1: string;
    fuelType1: string;
    handleDeleteLastRow: (index: number) => void
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    resources: InputResources[]
    currentLanguage: string
    edit1: boolean
    scopeType: string
}


const FuelComponent: React.FC<props> = ({ taskType, index, unit1, updateFuelData, value1, fuelType1, handleDeleteLastRow, setWarning, resources, currentLanguage, edit1, scopeType }) => {

    const theme = createMyTheme();
    const [fuelType, setFuelType] = useState<string>(fuelType1);
    const [unit, setUnit] = useState<string>(unit1);
    const [value, setValue] = useState<string>(value1);
    const [edit, setEdit] = useState<boolean>(!edit1);
    const { t } = useTranslation();
    const fuelListEng = resources ? resources.map((item) => item.nameEng) : []
    const fuelListIta = resources ? resources.map((item) => item.nameIta) : []
    let label1:string
    (scopeType === "S1" || scopeType == "M1") ? label1 = "fuel" : label1 = "gas"

    const handleEditChange = (event: React.SyntheticEvent<unknown>) => {
        if (edit) {

            if (fuelType === "" || value === "" || unit == "") setWarning(t("warning6"))
            else if (isNaN(parseInt(value))) setWarning(t("warning3"))
            else if (taskType === 'percentage' && (parseInt(value) >= 100 || parseInt(value) == 0)) setWarning(t("warning10"))
            else {
                const response = updateFuelData(index, value, unit, fuelType, true)
                if (response)
                    setEdit(false)
                else {
                    return
                }
            }
        }
        else {
            updateFuelData(index, "", "", "", false)
            setEdit(true)
        }

    }

    const handleDeleteChange = (event: React.SyntheticEvent<unknown>) => {


        if (index == 0) {
            const response = updateFuelData(index, "", "", "", false)
            setFuelType("")
            setValue("")
            if (taskType === "consumption")
                setUnit("")
            setEdit(true)
        } else {
            handleDeleteLastRow(index)
        }

    }

    return (


        <div style={{ display: "flex", alignItems: "center", gap: "3vw"}}>
            <Autocomplete style={{ width: "15vw" }}
                disabled={!edit}
                options={currentLanguage === "en" ? fuelListEng : fuelListIta}
                onChange={(_, value) => setFuelType(value || "")}
                value={fuelType}
                renderInput={(params) =>
                    <CustomTextField

                        {...params}
                        FormHelperTextProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{
                            style: { color: "black" }
                        }}
                        variant="standard"
                        label={t(label1)}

                    />}
            />



            <CustomTextField
                style={{ width: "15vw" }}
                autoComplete="off"
                disabled={!edit}
                FormHelperTextProps={{ style: { color: "black" } }}
                InputLabelProps={{
                    style: { color: "black" }
                }}
                onChange={(e) => setValue(e.target.value)}
                name="Name"
                variant="standard"
                label={t(taskType)}
                defaultValue={value}
                value={value}

            />
            {taskType === "consumption" && (
                <>

                    <Autocomplete style={{ width: "5vw" }}
                        disabled={!edit || fuelType == ""}
                        options={currentLanguage === "en" ? (resources.find((item) => item.nameEng === fuelType)?.uoms || []) : (resources.find((item) => item.nameIta === fuelType)?.uoms || [])}
                        onChange={(_, value) => setUnit(value || "")}
                        value={unit}
                        renderInput={(params) =>
                            <CustomTextField

                                {...params}
                                FormHelperTextProps={{ style: { color: "#fff" } }}
                                InputLabelProps={{
                                    style: { color: "black" }
                                }}
                                variant="standard"
                                label={t("unit")}

                            />}
                    />


                </>

            )}
            {(taskType !== "consumption") && (
                <p style={{ color: "black", fontSize: "1vw", textAlign: "center", width: "5vw", marginBottom: "-0.5vh" }}>{unit}</p>
            )}
            {edit && (
                <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                    <Button onClick={(e) => handleEditChange(e)}
                        variant="navigation"
                    ><DoneOutlineSharpIcon /></Button>
                    <Button onClick={(e) => handleDeleteChange(e)}
                        variant="navigation"
                    ><DeleteOutlineOutlinedIcon /></Button>
                </div>
            )
            }
            {!edit && (
                <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                    <Button onClick={(e) => handleEditChange(e)}
                        variant="navigation"
                    ><CreateSharpIcon /></Button>
                    <Button onClick={(e) => handleDeleteChange(e)}
                        variant="navigation"
                    ><DeleteOutlineOutlinedIcon /></Button>
                </div>
            )}

        </div>
    );
};


export default FuelComponent
