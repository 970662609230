import React, { useEffect, useState } from "react";
import '../../App.css';
import { Grid, Button, Paper, Autocomplete, Divider } from "@mui/material";
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from '../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../styles/CustomTextField";
import * as client from '../../Integration/api';
import { ClipLoader } from 'react-spinners';
import { Configuration, SubClusterResponse } from '../../Integration';
import { ModalComponent } from "../Components/ModalComponent";
import { BsTypeH2 } from "react-icons/bs";



export const Card2: React.FC = ({ }) => {

    const theme = createMyTheme();
    const navigate = useNavigate();
    const location = useLocation()
    const [loadingManuale, setLoadingManuale] = useState<boolean>(false);
    const [loadingQuestionario, setLoadingQuestionario] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)
    const token = sessionStorage.getItem("sessionToken")

    useEffect(() => {
        setCurrentLanguage(i18n.language)
    }, [i18n.language]);


    const handleContinue = () => {

        navigate(`/profile`)
    }

    const handleDownloadManuale = async () => {

        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)

        setLoadingManuale(true)
        clientAPI.downloadManuale(currentLanguage).then(response => {
            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = currentLanguage==="it" ? "CIT_manuale_utente" + ".pdf" :"CIT_user_manual" + ".pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
            setLoadingManuale(false)
        }).catch(error => {
            console.error(error)
        });

    }

    const handleDownloadQuestionario = async () => {

        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)

        setLoadingQuestionario(true)
        clientAPI.downloadQuestionario(currentLanguage).then(response => {
            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.ms-excel' });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = currentLanguage==="it" ? "CIT_form_raccolta_dati" + ".xlsx" :"CIT_data_collection_form" + ".xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
            setLoadingQuestionario(false)
        }).catch(error => {
            console.error(error)
        });

    }


    return (

        <ThemeProvider theme={theme}>

            <Grid
                container
                direction="column"
                display='flex'
                justifyContent="flex-start"
                alignItems="center"
                style={{ background: "#F5F5F5", minHeight: "93vh" }}
            >

                <>


                    <Grid item style={{ marginTop: "14vh", width: "80vw" }}>
                        <Paper
                            square
                            elevation={3}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: "center",
                                textAlign: "left",
                                pl: 2,
                                bgcolor: 'white',
                                gap:"-1vh",
                                fontStyle:"roboto"                               
                            }}
                        >
                            <p style={{ color: "#007CC1", fontWeight: 'bold', textAlign: 'center', fontSize: '1.2vw' }}>
                                {t("label1Card")}
                            </p>

                            <Divider style={{ width: "100%" }} />

                            <div >
                                <div>
                                    <p>
                                        {t("label2Card")} <a href='https://ghgprotocol.org/sites/default/files/standards/ghg-protocol-revised.pdf' target="_blank">GHG protocol</a> {t("label11Card")}
                                    </p>
                                </div>
                                <Divider style={{ width: "100%" }} />
                                <div>
                                    <p style={{ fontWeight: "bold" }}><b>{t("label3Card")}</b></p>
                                    <p>
                                        {t("label4Card")}
                                    </p>
                                </div>
                                <Divider style={{ width: "100%" }} />
                                <div>
                                    <p style={{ fontWeight: "bold" }}><b>{t("label5Card")}</b></p>
                                    <p>
                                        {t("label6Card")}
                                    </p>
                                </div>
                                <Divider style={{ width: "100%" }} />
                                <div>
                                    <p style={{ fontWeight: "bold" }}><b>{t("label7Card")}</b></p>
                                    <p>
                                        {t("label8Card")}
                                    </p>
                                </div>
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item style={{ marginTop: "2vh", width: "80vw", marginBottom: "5vh" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            gap="18vw"
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginLeft: "27vw", gap: "1vw" }}>
                                <Button
                                    variant="outlined"
                                    onClick={e => handleDownloadManuale()}
                                    style={{
                                        color: "#015F9F",
                                        borderColor: "#FFD600",
                                        background: "#FFD600",
                                        fontWeight: 'bold',
                                        fontSize: '0.9vw',
                                        width: "12vw",
                                        height: "5vh"
                                    }}
                                >
                                    {loadingManuale ? (
                                        <ClipLoader color={'#ffffff'} size={20} loading={loadingManuale} />
                                    ) : (
                                        <p> {t("label9Card")}</p>
                                    )
                                    }

                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={e => handleDownloadQuestionario()}
                                    style={{
                                        color: "#015F9F",
                                        borderColor: "#FFD600",
                                        background: "#FFD600",
                                        fontWeight: 'bold',
                                        fontSize: '0.9vw',
                                        width: "12vw",
                                        height: "5vh"
                                    }}
                                >
                                    {loadingQuestionario ? (
                                        <ClipLoader color={'#ffffff'} size={20} loading={loadingQuestionario} />
                                    ) : (
                                        <p> {t("label10Card")}</p>
                                    )
                                    }

                                </Button>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginLeft: "1vw" }}>
                                <Button
                                    onClick={(e) => handleContinue()}
                                    variant="navigation"
                                    style={{ width: "7vw" }}
                                >{t("enter")}</Button>
                            </div>
                        </Grid>
                    </Grid>
                </>
            </Grid>
        </ThemeProvider>
    )
}