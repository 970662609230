import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 600],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#007CC1' : 'gray',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#007CC1', // Cambia il colore dell'avanzamento a blu
  },
}));

export const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  border: "2px solid black",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 600],
    borderColor: "grey"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#007CC1' : '#F5F5F5',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#fac906', // Cambia il colore dell'avanzamento a blu
  },
}));







