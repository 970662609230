import React, { useEffect, useState } from 'react';
import { CustomTextField } from '../../styles/CustomTextField';
import { Grid, Button, Paper, Divider, Autocomplete } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from '../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import * as client from '../../Integration/api';
import { CompanyAnagrafica, Configuration, RevenueResponse, SubClusterResponse } from '../../Integration';
import { ClipLoader } from 'react-spinners';
import { ROLE_ADMIN, ROLE_USER } from "../../costants";



interface props {
    setSettore: React.Dispatch<React.SetStateAction<string>>,
    setWarning: React.Dispatch<React.SetStateAction<string>>,


}

export const Profile: React.FC<props> = ({ setSettore, setWarning }) => {

    const navigate = useNavigate();
    const theme = createMyTheme();
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>(true);
    const [revenue, setRevenue] = useState<string>("");
    const [company, setCompany] = React.useState<CompanyAnagrafica>()
    const [selectedYear, setSelectedYear] = React.useState<string>("");
    const token = sessionStorage.getItem("sessionToken")
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)
    const role = sessionStorage.getItem("role")
    const yearsStored = sessionStorage.getItem('years');
    const years = yearsStored ? JSON.parse(yearsStored) : [];

    useEffect(() => {
        setCurrentLanguage(i18n.language)
    }, [i18n.language]);


    useEffect(() => {
        if (loading) {
            const conf: Configuration = {
                accessToken: token ? token : "",
                baseOptions: {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                },
            };
            const clientAPI = new client.DefaultApi(conf)
            clientAPI.getProfile().then(res => {
                setCompany(res.data)
                setSettore(res.data.settoreIta)
                setLoading(false)
            }).catch(error => {
                console.error(error)
                if (error.response && error.response.status === 403) {
                    setWarning("session expired")
                    setTimeout(() => {
                        navigate("/openES");
                    }, 5000);
                }
            })

        }
    }, [loading]);


    const handleContinue = () => {

        if (role === ROLE_ADMIN) {

            if (company?.settoreIta !== "Altro" && company?.settoreIta !== "Costruzioni & Ingegneria") navigate("/cluster")
            else {
                const conf: Configuration = {
                    accessToken: token ? token : "",
                    baseOptions: {
                        headers: {
                            "Access-Control-Allow-Origin": "*"
                        }
                    },
                };
                const clientAPI = new client.DefaultApi(conf)
                setLoading(true)
                const body: SubClusterResponse = {
                    subClusterName: "Altro"
                }
                clientAPI.sendSubcluster(body).then(res => {
                    navigate("/scope1-S1")
                    setLoading(false)
                }).catch(error => {
                    console.error(error)
                    if (error.response && error.response.status === 403) {
                        setWarning("session Expired")
                        setTimeout(() => {
                            navigate("/openES");
                        }, 5000);
                    }
                })
            }
        }

        else {
            if (selectedYear === "") {
                setWarning(t("warning15"))
                return;
            }
            else {
                sessionStorage.setItem('reportYear', selectedYear.toString());
                navigate("/report")
            }
        }

    }

    return (

        <ThemeProvider theme={theme}>
            {loading ? (
                <ClipLoader size={150} color={'#007BFF'} loading={loading} style={{ marginTop: "20vh" }} />
            ) : (
                <>
                    <Grid
                        container
                        direction="column"
                        display='flex'
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="1vh"
                        style={{ background: "#F5F5F5", minHeight: "93vh" }}
                    >

                        <Grid item style={{ marginTop: "14vh", width: "80vw" }}>
                            <Paper
                                square
                                elevation={3}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    pl: 2,
                                    bgcolor: 'white',
                                    //padding: '1rem',

                                }}
                            >

                                <h2 style={{ color: "black" }}>
                                    {t("labelProfile1")}
                                </h2>

                                <Divider style={{ width: "100%" }} />

                                <Grid item style={{ marginTop: "3vh" }}>
                                    <CustomTextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        FormHelperTextProps={{ style: { color: "black" } }}
                                        style={{ width: "40vw" }}
                                        name="Name"
                                        variant="standard"
                                        value={company?.ragioneSociale}
                                        defaultValue={"2023"}
                                        helperText={t("labelProfile6")}
                                    />
                                </Grid>

                                {role === ROLE_ADMIN ? (

                                    < Grid item style={{ marginTop: "5vh" }}>
                                        <CustomTextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            FormHelperTextProps={{ style: { color: "black" } }}
                                            style={{ width: "40vw" }}
                                            name="Name"
                                            variant="standard"
                                            value={company?.annoFiscale}
                                            defaultValue={"2023"}
                                            helperText={t("labelProfile2")}
                                        />
                                    </Grid>) : (
                                    < Grid item style={{ marginTop: "5vh" }}>
                                        <Autocomplete style={{ width: "40vw" }}
                                            options={years}
                                            onChange={(_, value) => setSelectedYear(value || "")}
                                            value={selectedYear}

                                            renderInput={(params) =>
                                                <CustomTextField

                                                    {...params}
                                                    FormHelperTextProps={{ style: { color: "#fff" } }}
                                                    InputLabelProps={{
                                                        style: { color: "black" }
                                                    }}
                                                    variant="standard"
                                                    label={t("labelProfile2")}
                                                    defaultValue={t("labelProfile2")}
                                                    helperText={t("labelProfile2")}
                                                />}
                                        />
                                    </Grid>

                                )
                                }

                                <Grid item style={{ marginTop: "5vh" }}>
                                    <CustomTextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        FormHelperTextProps={{ style: { color: "black" } }}
                                        style={{ width: "40vw" }}
                                        name="Name"
                                        variant="standard"
                                        defaultValue={"Trasporti"}
                                        value={currentLanguage === "en" ? company?.settoreEng : company?.settoreIta}
                                        helperText={t("labelProfile3")}
                                    />
                                </Grid>

                                <Grid item style={{ marginTop: "5vh", marginBottom: "2rem" }} >
                                    <CustomTextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        FormHelperTextProps={{ style: { color: "black" } }}
                                        autoComplete="off"
                                        style={{ width: "40vw" }}
                                        name="Name"
                                        variant="standard"
                                        defaultValue={"Revenue"}
                                        value={company?.fatturato}
                                        helperText={t("labelProfile4")}
                                    />
                                </Grid>

                                {/* <Grid item style={{ marginTop: "5vh" }}>
                                    <CustomTextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        FormHelperTextProps={{ style: { color: "black" } }}
                                        style={{ width: "40vw" }}
                                        name="Name"
                                        variant="standard"
                                        value={company?.numeroImpiegati}
                                        defaultValue={"20000"}
                                        helperText={t("labelProfile5")}
                                    />
                                </Grid> */}

                            </Paper>
                        </Grid>



                        <Grid item style={{ marginTop: "1vh", width: "80vw", marginBottom: "5vh" }}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Grid item style={{ justifyContent: " flex-end" }}>
                                    <Button
                                        onClick={e => handleContinue()}
                                        style={{ width: "7vw" }}
                                        variant="navigation"
                                    >{t("continue")}</Button>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </>
            )}


        </ThemeProvider >
    );
}