import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "30vw",
        fontSize: "0.8vw",
        background: 'linear-gradient(180deg, #007CC1 0%, #015F9F 100%)',
        color: "white",
        fontStyle: "regular",
        fontWeight: 'bold'
    },
});