import React, { useEffect, useState } from "react";
import { Grid, Button, Divider, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Question, Input, InputAnswer } from "../../Integration/models";
import AddIcon from '@mui/icons-material/Add';
import FuelComponent from "../Scope1/FuelComponent";


interface props {

    input: Input
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    answers: InputAnswer[]
    question: Question
    currentLanguage: string
    scopeType: string

}

interface FuelData {
    id: number; // Identificazione univoca
    value: string;
    unit: string;
    fuelType: string;
    isCompilated: boolean
}

const FuelQuestion: React.FC<props> = ({ input, setAnswers, answers, question, setWarning, currentLanguage, scopeType }) => {

    const { t } = useTranslation();
    const [fuelDataList, setFuelDataList] = useState<FuelData[]>(() => {
        return answers.some(item => (item.inputId == input.id) && (item.questionId == question.id)) ?
            answers.filter(item => (item.inputId == input.id) && (item.questionId == question.id)).map((item, index) => {
                let e: FuelData = {
                    id: index,
                    value: item.quantity ? item.quantity.toString() : "",
                    unit: item.uom ? item.uom : "",
                    fuelType: item.fuel ? item.fuel : "",
                    isCompilated: true
                }
                return e;
            }) : []
    })
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {

        if (fuelDataList.length == 0) addFuelData()

        setInitialized(true);
    }, []);

    useEffect(() => {

        const list = answers.filter(item => (item.inputId != input.id) || item.questionId != question.id)
        const newList = fuelDataList.filter(item => item.fuelType !== "").map(item => {
            const e: InputAnswer = {
                inputId: input.id,
                questionId: question.id,
                yesOrNoAnswer: false,
                quantity: parseInt(item.value),
                uom: item.unit,
                country: "",
                fuel: item.fuelType
            }
            return e

        })
        const updatedList = [...list, ...newList]
        setAnswers(updatedList)
    }, [fuelDataList]);

    const updateFuelData = (index: number, value: string, unit: string, fuelType: string, edit: boolean): boolean => {

        let isDuplicate = false;

        if (fuelType !== "") {
            isDuplicate = fuelDataList.some((item) => item.fuelType === fuelType);
        }

        if (!isDuplicate) {
            if (answers.some(item => item.fuel === fuelType)) setWarning(t("warning14"))
            const updatedData = fuelDataList.map(data => {
                if (data.id === index) {
                    return { ...data, id: index, value: value, unit: unit, fuelType: fuelType, isCompilated: edit };
                }
                return data;
            });
            setFuelDataList(updatedData);
            return true;
        }
        else {
            setWarning(t("warning7"))
            return false;
        }
    };

    const updateFuelDataPerc = (index: number, value: string, unit: string, fuelType: string) => {

        let isDuplicate = false;

        if (fuelType !== "") {
            isDuplicate = fuelDataList.some((item) => item.fuelType === fuelType);

            if (fuelDataList.length > 1) {

                let dim = fuelDataList.length
                var count = 0

                for (let i = 0; i < dim - 1; i++) {

                    count = count + parseInt(fuelDataList[i].value)

                }

                if (count + parseInt(value) >= 100) {
                    setWarning(t("warning4"))
                    return false
                }
            }
        }

        if (!isDuplicate) {
            if (answers.some(item => item.fuel === fuelType)) setWarning(t("warning14"))
            const updatedData = fuelDataList.map(data => {
                if (data.id === index) {
                    return { ...data, id: index, value: value, unit: unit, fuelType: fuelType, isCompilated: true };
                }
                return data;
            });
            setFuelDataList(updatedData);
            return true;
        }

        else {
            setWarning(t("warning7"))
            return false;
        }
    };


    const addFuelData = () => {

        if (fuelDataList.length == 0 || fuelDataList[fuelDataList.length - 1].value !== "") {
            const newId = fuelDataList.length;
            const emptyFuelData: FuelData = {
                id: newId,
                value: "",
                unit: "",
                fuelType: "",
                isCompilated: false,
            };

            setFuelDataList([...fuelDataList, emptyFuelData]);
        }
        else {
            setWarning(t("warning5"))
        }

    };

    const handleDeleteLastRow = (index: number) => {
        if (fuelDataList.length > 0 && index == fuelDataList.length - 1) {
            const updatedList = fuelDataList.slice(0, -1);
            setFuelDataList(updatedList);
        }
    };

    if (!initialized) {
        return null;
    }



    return (
        <>
            <Grid container
                spacing={2}
                direction="column"
                display='flex'
                alignItems="center"
                marginTop={'3vh'}
                marginBottom={'1vh'}
                justifyContent={'center'}
            >
                <>
                    <>
                        {(input.isCurrency == false) && (input.isPercentage == false) && (input.isKgco2eq == false) &&
                            <>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-2vh", border: '3px solid #007CC1', padding: "0.5rem" }}>
                                    {t("scope1Option1")}
                                </p>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom:"4vh" }}>
                                    {t("scope1Option12")}
                                </p>
                            </>
                        }
                        {input.isCurrency == true &&
                            <>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-2vh", border: '3px solid #007CC1', padding: "0.5rem" }}>
                                    {t("scope1Option2")}
                                </p>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom:"4vh" }}>
                                    {t("scope1Option22")}
                                </p>
                            </>
                        }
                        {input.isKgco2eq == true &&
                            <>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-2vh", border: '3px solid #007CC1', padding: "0.5rem" }}>
                                    {t("scope1Option3")}
                                </p>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom:"4vh" }}>
                                    {t("scope1Option32")}
                                </p>
                            </>
                        }
                        {input.isPercentage == true &&
                            <>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-2vh", border: '3px solid #007CC1', padding: "0.5rem" }}>
                                    {t("scope1Option4")}
                                </p>
                                <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom:"4vh" }}>
                                    {t("scope1Option42")}
                                </p>
                            </>
                        }
                    </>

                    {fuelDataList.map((data, index) => (
                        <div key={index} style={{ marginBottom: '1rem', alignItems: "center", marginTop: "-2vh" }}>
                            {(input.isCurrency == true) && <FuelComponent taskType="spending" index={index} unit1="€" updateFuelData={updateFuelData} value1={data.value} fuelType1={data.fuelType} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} resources={input.resources} currentLanguage={currentLanguage} edit1={data.isCompilated} scopeType={scopeType} />}
                            {(input.isPercentage == true) && <FuelComponent taskType="percentage" index={index} unit1="%" updateFuelData={updateFuelDataPerc} value1={data.value} fuelType1={data.fuelType} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} resources={input.resources} currentLanguage={currentLanguage} edit1={data.isCompilated} scopeType={scopeType} />}
                            {(input.isKgco2eq == true) && <FuelComponent taskType="emission" index={index} unit1="KgCO2e" updateFuelData={updateFuelData} value1={data.value} fuelType1={data.fuelType} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} resources={input.resources} currentLanguage={currentLanguage} edit1={data.isCompilated} scopeType={scopeType} />}
                            {(input.isCurrency == false) && (input.isPercentage == false) && (input.isKgco2eq == false) && <FuelComponent taskType="consumption" index={index} unit1={data.unit} updateFuelData={updateFuelData} value1={data.value} fuelType1={data.fuelType} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} resources={input.resources} currentLanguage={currentLanguage} edit1={data.isCompilated} scopeType={scopeType} />}
                        </div>
                    ))}
                    <Box style={{ backgroundColor: "#007CC1", borderRadius: "100px", display: "flex", padding: "0.5rem", cursor: "pointer", transition: "background-color 0.3s" }} onClick={addFuelData}>
                        <AddIcon style={{ color: 'white', justifyContent: "center", fontSize: "1.2vw" }}></AddIcon>
                    </Box>
                </>
            </Grid>
        </>
    )

}

export default FuelQuestion