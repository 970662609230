import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface Props {
    dato1: number | undefined;
    dato2: number | undefined;
    label1: string;
    label2: string;

}

const BarChart: React.FC<Props> = ({ dato1, dato2, label1, label2 }) => {
    const [chartHeight, setChartHeight] = useState<number>(200);
    const [chartWidth, setChartWidth] = useState<number>(300);

    useEffect(() => {
        const handleResize = () => {
            setChartHeight(window.innerHeight * 0.3); // Ridotto al 50% dell'altezza dello schermo
            setChartWidth(window.innerWidth * 0.35); // Ridotto all'80% della larghezza dello schermo
        };

        // Aggiungi un listener per il ridimensionamento della finestra
        window.addEventListener('resize', handleResize);

        // Inizializza le dimensioni del grafico quando il componente viene montato
        handleResize();

        // Rimuovi il listener quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const options: ApexOptions = {
        series: [{
            name: label1,
            data: [dato1 ? dato1 : 0]
        }, {
            name: label2,
            data: [dato2 ? dato2 : 0]
        }],
        chart: {
            type: 'bar',
            height: 430,
            toolbar: {
                show: false, // Nascondi il menu del grafico
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
                barHeight: chartHeight * 0.15

            },
        },

        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['yellow'],

            },
        },
        stroke: {
            show: true,
            width: 0.5,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        xaxis: {
            categories: ['CO2/M€'], // Solo due categorie
        },
        colors: ['#fac906', '#007CC1'], // Colori personalizzati per le barre
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 60
        }


    };

    return (
        <div >
            <ReactApexChart options={options} series={options.series} type="bar" height={chartHeight} width={chartWidth} />
        </div>
    );
};

export default BarChart;
