import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomTextField } from '../../styles/CustomTextField';
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from '../../styles/theme';
import { Button } from "@mui/material";
import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import { InputResources } from "../../Integration/models";

interface SelectChangeEvent<T> {
    target: {
        value: T;
    };
}

export interface props {

    taskType: string;
    index: number;
    unit1: string;
    updateBasicData: (index: number, value: string, unit: string, country: string, edit: boolean) => boolean;
    value1: string;
    country1: string;
    handleDeleteLastRow: (index: number) => void
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    resources: InputResources[]
    currentLanguage: string,
    edit1: boolean
}

const unitList = ["kWh", "MWh"]

const CountryComponent2: React.FC<props> = ({ taskType, index, unit1, updateBasicData, value1, country1, handleDeleteLastRow, setWarning, resources, currentLanguage, edit1 }) => {

    const theme = createMyTheme();
    const { t } = useTranslation();
    const [country, setCountry] = useState<string>(country1);
    const [unit, setUnit] = useState<string>(unit1);
    const [value, setValue] = useState<string>(value1);
    const [edit, setEdit] = useState<boolean>(!edit1);
    const countryListEng = resources ? resources.map((item) => item.nameEng) : []
    const countryListIta = resources ? resources.map((item) => item.nameIta) : []


    const handleEditChange = (event: React.SyntheticEvent<unknown>) => {
        if (edit) {
            if (country === "" || value === "" || unit == "") setWarning(t("warning6"))
            else if (isNaN(parseInt(value))) setWarning(t("warning3"))
            else {
                const response = updateBasicData(index, value, unit, country, true)
                if (response)
                    setEdit(false)
                else {
                    return;
                }
            }

        }

        else {
            updateBasicData(index, "", "", "", false)
            setEdit(true)
        }

    }

    const handleDeleteChange = (event: React.SyntheticEvent<unknown>) => {

        if (index == 0) {
            const response = updateBasicData(index, "", "", "", false)
            setCountry("")
            setValue("")
            if (taskType === "consumi")
                setUnit("")
            setEdit(true)
        } else {
            handleDeleteLastRow(index)
        }

    }

    return (

        <>
            <div style={{ display: "flex", alignItems: "center", gap: "3vw" }}>
                <Autocomplete style={{ width: "10vw" }}
                    disabled={!edit}
                    options={currentLanguage === "en" ? countryListEng : countryListIta}
                    onChange={(_, value) => setCountry(value || "")}
                    value={country}
                    renderInput={(params) =>
                        <CustomTextField

                            {...params}
                            FormHelperTextProps={{ style: { color: "black" } }}
                            InputLabelProps={{
                                style: { color: "black" }
                            }}
                            variant="standard"
                            label={t("country")}


                        />}
                />



                <CustomTextField
                    autoComplete="off"
                    disabled={!edit}
                    FormHelperTextProps={{ style: { color: "black" } }}
                    InputLabelProps={{
                        style: { color: "black" }
                    }}
                    onChange={(e) => setValue(e.target.value)}
                    name="Name"
                    variant="standard"
                    label={t("value")}
                    defaultValue={value}
                    value={value}

                />
                {taskType === "consumi" && (
                    <Autocomplete style={{ width: "10vw" }}
                        disabled={!edit || country == ""}
                        options={unitList}
                        onChange={(_, value) => setUnit(value || "")}
                        value={unit}
                        renderInput={(params) =>
                            <CustomTextField

                                {...params}
                                FormHelperTextProps={{ style: { color: "black" } }}
                                InputLabelProps={{
                                    style: { color: "black" }
                                }}
                                variant="standard"
                                label={t("unit")}


                            />}
                    />
                )}
                {(taskType !== "consumi") && (
                    <p style={{ color: "black", fontSize: "1vw", textAlign: "center", width: "10vw", marginBottom: "-0.5vh" }}>{unit}</p>
                )}
                {edit && (
                    <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                        <Button onClick={(e) => handleEditChange(e)}
                            variant="navigation"
                        ><DoneOutlineSharpIcon /></Button>
                        <Button onClick={(e) => handleDeleteChange(e)}
                            variant="navigation"
                        ><DeleteOutlineOutlinedIcon /></Button>
                    </div>
                )
                }
                {!edit && (
                    <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                        <Button onClick={(e) => handleEditChange(e)}
                            variant="navigation"
                        ><CreateSharpIcon /></Button>
                        <Button onClick={(e) => handleDeleteChange(e)}
                            variant="navigation"
                        ><DeleteOutlineOutlinedIcon /></Button>
                    </div>
                )}

            </div>
        </>
    );
};


export default CountryComponent2
