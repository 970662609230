import React, { useEffect, useState } from "react";
import { Grid, Button, Divider, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Question, Input, InputAnswer } from "../../Integration/models";
import AddIcon from '@mui/icons-material/Add';
import CountryComponent from "../Scope2/CountryComponent";


interface props {

    input: Input
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    answers: InputAnswer[]
    question: Question
    currentLanguage: string


}


interface countryData {
    id: number; // Identificazione univoca
    value: string;
    unit: string;
    country: string;
    isCompleted: boolean
}

const CountryQuestion: React.FC<props> = ({ input, setAnswers, answers, question, setWarning, currentLanguage }) => {

    const { t } = useTranslation();
    const [countryDataList, setCountryDataList] = useState<countryData[]>(() => {
        return answers.some(item => (item.inputId == input.id) && (item.questionId == question.id)) ?
            answers.filter(item => (item.inputId == input.id) && (item.questionId == question.id)).map((item, index) => {
                let e: countryData = {
                    id: index,
                    value: item.quantity ? item.quantity.toString() : "",
                    unit: item.uom ? item.uom : "",
                    country: item.country ? item.country : "",
                    isCompleted: true
                }
                return e;
            }) : []
    })
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {

        if (countryDataList.length == 0) addCountryData()

        setInitialized(true);
    }, []);


    useEffect(() => {

        const list = answers.filter(item => (item.inputId != input.id) || item.questionId != question.id)
        const newList = countryDataList.filter(item => item.country !== "").map(item => {
            const e: InputAnswer = {
                inputId: input.id,
                questionId: question.id,
                yesOrNoAnswer: false,
                quantity: parseInt(item.value),
                uom: item.unit,
                country: item.country
            }
            return e
        })

        const updatedList = [...list, ...newList]
        setAnswers(updatedList)


    }, [countryDataList]);

    const updateCountryData = (index: number, value: string, unit: string, country: string, edit: boolean): boolean => {

        let isDuplicate = false;
        if (country !== "") {
            isDuplicate = countryDataList.some((item) => item.country === country);
        }

        if (!isDuplicate) {
            if (answers.some(item => item.country === country)) setWarning(t("warning17"))
            const updatedData = countryDataList.map(data => {
                if (data.id === index) {
                    return { ...data, id: index, value: value, unit: unit, country: country, isCompleted: edit };
                }
                return data;
            });
            setCountryDataList(updatedData);
            return true;
        }
        else {
            setWarning(t("warning8"))
            return false;
        }


    };

    const addCountryData = () => {

        if (countryDataList.length == 0 || countryDataList[countryDataList.length - 1].value !== "") {
            const newId = countryDataList.length;
            const emptyData: countryData = {
                id: newId,
                value: "",
                unit: "",
                country: "",
                isCompleted: false
            };

            setCountryDataList([...countryDataList, emptyData]);
        }
        else {
            setWarning(t("warning5"))
        }


    };

    const handleDeleteLastRow = (index: number) => {
        if (countryDataList.length > 0 && index == countryDataList.length - 1) {
            const updatedList = countryDataList.slice(0, -1);
            setCountryDataList(updatedList);
        }
    };


    if (!initialized) {
        return null;
    }



    return (
        <>
            <Grid container
                spacing={2}
                direction="column"
                display='flex'
                alignItems="center"
                marginTop={'3vh'}
                marginBottom={'1vh'}
                paddingBottom={'1vh'}
                justifyContent={'center'}

            >
                <>
                  
                    {input.isCurrency == true &&
                        <>
                            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-2vh", border: '3px solid #007CC1', padding: "0.5rem" }}>
                                {t("scope2Option2")}
                            </p>
                            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom:"4vh" }}>
                                {t("scope2Option22")}
                            </p>
                        </>
                    }{input.isCurrency == false &&
                        <>
                            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-2vh", border: '3px solid #007CC1', padding: "0.5rem" }}>
                                {t("scope2Option1")}
                            </p>
                            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom:"4vh" }}>
                                {t("scope2Option12")}
                            </p>
                        </>
                    }
                    
                    {countryDataList.map((data, index) => (

                        <div key={index} style={{ marginBottom: '1rem', marginTop: "-2vh" }}>
                            {(input.isCurrency == true) && <CountryComponent taskType="spending" index={index} unit1="€" updateBasicData={updateCountryData} value1={data.value} country1={data.country} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} resources={input.resources} currentLanguage={currentLanguage} edit1={data.isCompleted} />}
                            {(input.isCurrency == false) && <CountryComponent taskType="consumi" index={index} unit1={data.unit} updateBasicData={updateCountryData} value1={data.value} country1={data.country} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} resources={input.resources} currentLanguage={currentLanguage} edit1={data.isCompleted} />}
                        </div>
                    ))}


                    <Box style={{ backgroundColor: "#007CC1", borderRadius: "100px", display: "flex", padding: "0.5rem", cursor: "pointer", transition: "background-color 0.3s" }} onClick={addCountryData}>
                        <AddIcon style={{ color: 'white', justifyContent: "center", fontSize: "1.2vw" }}></AddIcon>
                    </Box>

                </>
            </Grid>

        </>
    )

}

export default CountryQuestion