import React, { useEffect } from "react";
import { Input, InputAnswer, Question } from "../../Integration/models";
import FuelQuestion from "../Questions/FuelQuestion";
import { Button, Divider, Paper, fabClasses } from "@mui/material";
import { CustomWidthTooltip } from "../../styles/Customtooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CountryQuestion from "../Questions/CountryQuestion";
import { CustomTextField } from "../../styles/CustomTextField";
import { useTranslation } from "react-i18next";
import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EmissionQuestion from "../Questions/EmissionQuestion";

interface props {
    inputs: Input[] | undefined
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    answers: InputAnswer[]
    question: Question
    currentLanguage: string

}

const CountryQuestionController: React.FC<props> = ({ inputs, setAnswers, answers, question, setWarning, currentLanguage }) => {

    const { t } = useTranslation();


    return (<>
        <Paper
            square
            elevation={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 2,
                bgcolor: 'white',
                gap: "1vh",
                paddingBottom:"1vh"

            }}
        >
            <h2 style={{ color: "black", fontSize: "1vw", textAlign: "center" }}>
                {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
            </h2>

            <p style={{ color: "black", fontSize: "1vw", fontStyle: "italic", marginTop: "-5vh", alignContent: "center" }} >{currentLanguage === "en" ? question?.subtitleEng : question?.subtitleIta}</p>
            {inputs?.filter(item => !item.isKgco2eq).map((data, index) => (<>
                <Divider style={{ width: "100%", color: "black" }} />
                <CountryQuestion key={index} input={data} setAnswers={setAnswers} answers={answers} question={question} setWarning={setWarning} currentLanguage={currentLanguage} />
            </>
            ))
            }
            {inputs?.filter(item => item.isKgco2eq).map((data, index) => (<>
                <Divider style={{ width: "100%", color: "black" }} />
                <EmissionQuestion key={index} input={data} setAnswers={setAnswers} answers={answers} question={question} setWarning={setWarning} currentLanguage={currentLanguage} />
            </>
            ))
            }
        </Paper>

    </>
    )
}

export default CountryQuestionController