import React, { useEffect, useState } from "react";
import { Grid, Button, Divider, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Question, Input, InputAnswer } from "../../Integration/models";
import { CustomTextField } from "../../styles/CustomTextField";
import { CustomWidthTooltip } from "../../styles/Customtooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface props {

    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    answers: InputAnswer[]
    question: Question
    currentLanguage: string
    inputs: Input[] | undefined
}

const HeatCooling: React.FC<props> = ({ setAnswers, answers, question, setWarning, currentLanguage, inputs }) => {

    const inputID1 = question?.inputs?.find(item => item.isYesNo == true)?.id
    const inputID2 = question?.inputs?.find(item => item.isQuantitative == true)?.id
    const [edit1, setEdit1] = React.useState<boolean>(true);
    const [edit2, setEdit2] = React.useState<boolean>(true);
    const [value1, setValue1] = useState<string>(() => {
        const flag = answers.some(item => item.questionId == question?.id && item.uom == "steam")
        if (flag) {
            let val = answers.find(item => item.questionId == question?.id && item.uom == "steam")
            setEdit1(false)
            return val?.quantity ? val?.quantity.toString() : ""
        }
        else
            return ""
    });
    const [value2, setValue2] = useState<string>(() => {
        const flag = answers.some(item => item.questionId == question?.id && item.uom == "cool")
        if (flag) {
            let val = answers.find(item => item.questionId == question?.id && item.uom == "cool")
            setEdit2(false)
            return val?.quantity ? val?.quantity.toString() : ""
        }
        else
            return ""
    });
    const { t } = useTranslation();


    const handleEdit1Change = (event: React.SyntheticEvent<unknown>) => {
        if (edit1) {
            if (value1 === "") setWarning(t("warning6"))
            else if (isNaN(parseInt(value1))) setWarning(t("warning3"))
            else {
                const list = answers.filter(item => (item.uom !== "steam") || item.questionId != question.id)
                const e1: InputAnswer = {
                    inputId: inputID2,
                    questionId: question.id,
                    quantity: parseInt(value1),
                    uom: "steam",
                    fuel: "steam"
                }

                const updatedList = [...list, e1]
                setAnswers(updatedList)
                setEdit1(false)
            }

        }


        else {
            const list = answers.filter(item => (item.uom !== "steam") || item.questionId != question.id)
            setAnswers(list)
            setEdit1(true)
        }
    }

    const handleEdit2Change = (event: React.SyntheticEvent<unknown>) => {

        if (edit2) {
            if (value2 === "") setWarning(t("warning6"))
            else if (isNaN(parseInt(value2))) setWarning(t("warning3"))
            else {
                const list = answers.filter(item => (item.uom !== "cool") || item.questionId != question.id)
                const e1: InputAnswer = {
                    inputId: inputID2,
                    questionId: question.id,
                    quantity: parseInt(value2),
                    uom: "cool",
                    fuel: "cool"
                }

                const updatedList = [...list, e1]
                setAnswers(updatedList)
                setEdit2(false)
            }

        }

        else {
            const list = answers.filter(item => (item.uom !== "cool") || item.questionId != question.id)
            setAnswers(list)
            setEdit2(true)
        }
    }

    const handleDeleteChange1 = (event: React.SyntheticEvent<unknown>) => {

        setValue1("")
        const list = answers.filter(item => (item.uom !== "steam") || item.questionId != question.id)
        setAnswers(list)
        setEdit1(true)

    }
    const handleDeleteChange2 = (event: React.SyntheticEvent<unknown>) => {

        setValue2("")
        const list = answers.filter(item => (item.uom !== "cool") || item.questionId != question.id)
        setAnswers(list)
        setEdit2(true)

    }

    return (<>
        <Paper
            square
            elevation={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 2,
                bgcolor: 'white',
                paddingBottom: '1rem',
            }}
        >

            <h2 style={{ color: "black", fontSize: "1vw", textAlign: "center" }}>
                {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
            </h2>

            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", alignContent: "center", marginTop: "-2vh" }} >{currentLanguage === "en" ? question?.exampleEng : question?.exampleIta}</p>

            <Grid item>
                <Grid
                    container
                    direction="row"
                    display='flex'
                    justifyContent="center"
                    alignItems="center"
                    width="50vw"

                >

                    <Grid item xs={5} >
                        <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", marginBottom: "-1vh", border: "solid 2px #007CC1", textAlign: "center" }}>
                            {t("text1E2")}
                        </p>
                    </Grid>
                    <Grid item xs={2} style={{ marginLeft: "3vw" }}>
                        <CustomTextField
                            autoComplete="off"
                            disabled={!edit1}
                            FormHelperTextProps={{ style: { color: "black" } }}
                            InputLabelProps={{
                                style: { color: "black" }
                            }}
                            onChange={(e) => setValue1(e.target.value)}
                            name="Name"
                            variant="standard"
                            label={t("value")}
                            defaultValue={value1}
                            value={value1}

                        />
                    </Grid>
                    <Grid item xs={2}>
                        <p style={{ color: "black", fontSize: "1vw", textAlign: "center", marginBottom: "1vh" }}>kWh</p>
                    </Grid>
                    <Grid item xs={2}>
                        {edit1 && (
                            <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                                <Button onClick={(e) => handleEdit1Change(e)}
                                    variant="navigation"
                                ><DoneOutlineSharpIcon /></Button>
                                <Button onClick={(e) => handleDeleteChange1(e)}
                                    variant="navigation"
                                ><DeleteOutlineOutlinedIcon /></Button>
                            </div>
                        )
                        }
                        {!edit1 && (
                            <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                                <Button onClick={(e) => handleEdit1Change(e)}
                                    variant="navigation"
                                ><CreateSharpIcon /></Button>
                                <Button onClick={(e) => handleDeleteChange1(e)}
                                    variant="navigation"
                                ><DeleteOutlineOutlinedIcon /></Button>
                            </div>
                        )}
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    display='flex'
                    justifyContent="center"
                    alignItems="center"
                    marginTop={'3vh'}
                    marginBottom={'2vh'}
                    width="50vw"

                >

                    <Grid item xs={5} >
                        <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", marginBottom: "-1vh", border: "solid 2px #007CC1", textAlign: "center" }}>
                            {t("text2E2")}
                        </p>
                    </Grid>
                    <Grid item xs={2} style={{ marginLeft: "3vw" }}>
                        <CustomTextField
                            autoComplete="off"
                            disabled={!edit2}
                            FormHelperTextProps={{ style: { color: "black" } }}
                            InputLabelProps={{
                                style: { color: "black" }
                            }}
                            onChange={(e) => setValue2(e.target.value)}
                            name="Name"
                            variant="standard"
                            label={t("value")}
                            defaultValue={value2}
                            value={value2}


                        />
                    </Grid>
                    <Grid item xs={2}>
                        <p style={{ color: "black", fontSize: "1vw", textAlign: "center", marginBottom: "1vh" }}>kWh</p>
                    </Grid>
                    <Grid item xs={2}>
                        {edit2 && (
                            <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                                <Button onClick={(e) => handleEdit2Change(e)}
                                    variant="navigation"
                                ><DoneOutlineSharpIcon /></Button>
                                <Button onClick={(e) => handleDeleteChange2(e)}
                                    variant="navigation"
                                ><DeleteOutlineOutlinedIcon /></Button>
                            </div>
                        )
                        }
                        {!edit2 && (
                            <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                                <Button onClick={(e) => handleEdit2Change(e)}
                                    variant="navigation"
                                ><CreateSharpIcon /></Button>
                                <Button onClick={(e) => handleDeleteChange2(e)}
                                    variant="navigation"
                                ><DeleteOutlineOutlinedIcon /></Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>

        </Paper>
    </>
    )
}

export default HeatCooling