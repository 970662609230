import React, { useEffect, useState } from 'react'
import { Configuration } from './Integration';
import * as client from './Integration/api';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from './styles/theme';
import { ClipLoader } from 'react-spinners';
import { SigninwithtokenBody } from './Integration';
import { ROLE_ADMIN, ROLE_USER, OPENES_URL } from "./costants";
import { Grid } from '@mui/material';
interface props {

  setToken: React.Dispatch<React.SetStateAction<string | undefined>>,
  setWarning: React.Dispatch<React.SetStateAction<string>>,
  setRole: React.Dispatch<React.SetStateAction<string | undefined>>,
}

const conf: Configuration = {
  accessToken: undefined,
  baseOptions: {
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  },
};

const clientAPI = new client.DefaultApi(conf)

export const Login: React.FC<props> = ({ setToken, setWarning, setRole }) => {

  const navigate = useNavigate();
  const theme = createMyTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const searchParams = new URLSearchParams(document.location.search)

  useEffect(() => {

    const token = searchParams.get("token")
    if (token) {
      const body: SigninwithtokenBody = {
        tok: token ? token : ""
      }
      clientAPI.getToken(body).then(res => {
        setToken(res.data.token)
        setRole(res.data.roles)
        sessionStorage.setItem('sessionToken', res.data.token);
        sessionStorage.setItem('completedSteps', "0");
        sessionStorage.setItem('role', res.data.roles);
        sessionStorage.setItem('years', JSON.stringify(res.data.years.map(item => item.toString())));
        const year = res.data.years?.[0];
        if (year !== undefined) {
          sessionStorage.setItem('reportYear', year.toString());
        }
        res.data.roles === ROLE_ADMIN ? navigate("/welcome") : navigate("/profile")
        setLoading(false)
      }).catch(error => {

        if (error.response && error.response.status == 401) {
          setWarning("Invalid token")
          setTimeout(() => {
            setLoading(false)
            redirect(OPENES_URL);
          }, 2000);
        }
      })
    }
    else {
      setWarning("Invalid session")
      setTimeout(() => {
        redirect(OPENES_URL);
      }, 2000);
    }

  }, []);

  return (

    <ThemeProvider theme={theme}>

      <Grid
        container
        direction="column"
        display='flex'
        justifyContent="flex-start"
        alignItems="center"
        gap="2vh"
        style={{ background: "#F5F5F5", minHeight: "93vh" }}

      >
        {
          loading && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "30vh" }}>
              <ClipLoader size={150} color={'#007BFF'} loading={loading} />
            </div >
          )
        }
      </Grid>
    </ThemeProvider>

  )
}
