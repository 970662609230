import React, { useEffect, useState } from "react";
import { Grid, Button,Paper, Autocomplete } from "@mui/material";
import { ThemeProvider } from '@emotion/react';
import { createMyTheme } from '../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../styles/CustomTextField";
import * as client from '../../Integration/api';
import { ClipLoader } from 'react-spinners';
import { Configuration, SubClusterResponse } from '../../Integration';
import { ModalComponent } from "../Components/ModalComponent";



export const ClusterQuestion: React.FC = ({ }) => {

    const theme = createMyTheme();
    const navigate = useNavigate();
    const location = useLocation()
    const [open, setOpen] = React.useState(false);
    const [warning, setWarning] = React.useState("");
    const [value, setValue] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true);
    const [settoriEng, setSettoriEng] = useState<string[]>([]);
    const [settoriIta, setSettoriIta] = useState<string[]>([]);
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)
    const token = sessionStorage.getItem("sessionToken")

    useEffect(() => {
        setCurrentLanguage(i18n.language)
    }, [i18n.language]);


    useEffect(() => {

        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)
        clientAPI.getSubClusters().then(res => {
            setSettoriEng(res.data.subClustersEng)
            setSettoriIta(res.data.subClustersIta)
            setLoading(false)
        }).catch(error => {
            console.error(error)
            if (error.response && error.response.status === 403) {
                setWarning("session Expired")
                setTimeout(() => {
                    navigate("/openES");
                }, 5000);
            }
        })


    }, []);

    useEffect(() => {

        if (warning !== "")
            setOpen(true)

    }, [warning]);

    const handleContinue = () => {

        if (value !== "") {

            const conf: Configuration = {
                accessToken: token ? token : "",
                baseOptions: {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                },
            };
            const clientAPI = new client.DefaultApi(conf)
            setLoading(true)
            const body: SubClusterResponse = {
                subClusterName: value
            }
            clientAPI.sendSubcluster(body).then(res => {
                setLoading(false)
                navigate("/scope1-S1")
            }).catch(error => {
                console.error(error)
                if (error.response && error.response.status === 403) {
                    setWarning("session Expired")
                    setTimeout(() => {
                        navigate("/openES");
                    }, 5000);
                }
            })


        }
        else setWarning(t("warning1"))
    }


    return (

        <ThemeProvider theme={theme}>

            <Grid
                container
                direction="column"
                display='flex'
                justifyContent="flex-start"
                alignItems="center"
                gap="1vh"
                style={{ background: "#F5F5F5", minHeight: "93vh" }}

            >
                <ModalComponent warning={warning} setWarning={setWarning} open={open} setOpen={setOpen}/>
                {loading ? (
                    <ClipLoader size={150} color={'#007BFF'} loading={loading} style={{ marginTop: "10vh" }} />
                ) : (
                    <>
                        <Grid item style={{ marginTop: "14vh", width: "80vw" }}>
                            <Paper
                                square
                                elevation={3}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    pl: 2,
                                    bgcolor: 'white',
                                    padding: '1rem',
                                    paddingBottom:"3rem",
                                    gap:"2vh"
                                }}
                            >
                                <h2 style={{ color: "black", fontSize: "1.1vw" }}>
                                    {t("questionSettore")}
                                </h2>

                                <Autocomplete style={{ width: "30vw" }}
                                    options={currentLanguage === "en" ? settoriEng : settoriIta}
                                    onChange={(_, value) => setValue(value || "")}
                                    value={value}
                                    renderInput={(params) =>
                                        <CustomTextField
                                            {...params}
                                            FormHelperTextProps={{ style: { color: "#fff" } }}
                                            InputLabelProps={{
                                                style: { color: "black" }
                                            }}
                                            variant="standard"
                                            label={t("sector")}
                                        />}
                                />
                            </Paper>
                        </Grid>
                        <Grid item style={{ marginTop: "1vh", width: "80vw", marginBottom: "5vh" }}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Grid item style={{ justifyContent: " flex-end" }}>
                                    <Button
                                        onClick={e => handleContinue()}
                                        style={{ width: "7vw" }}
                                        variant="navigation"
                                    >{t("continue")}</Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </ThemeProvider>
    )
}