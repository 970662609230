import React from 'react';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const CustomSelect = styled(Select)(({ theme }) => ({
    select: {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white',
        },
        '&:focus': {
            outline: 'none', // Rimuove il bordo quando il Select è cliccato
            borderColor: 'transparent', // Imposta il colore del bordo su trasparente
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Imposta il colore del bordo dello sfondo su trasparente
        },
        '& .MuiInputBase-input': {
            borderColor: "black"
        }
    },
}));