import React, { useEffect, useState } from 'react'
import { Question, Input, InputAnswer } from "../../Integration/models";
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import EmissionComponent from '../Scope2/EmissionComponent';
import AddIcon from '@mui/icons-material/Add';

interface props {

    input: Input
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    answers: InputAnswer[]
    question: Question
    currentLanguage: string
}

interface emissionData {
    id: number; // Identificazione univoca
    value: string;
    value2: string;
    isCompleted: boolean;
}

const EmissionQuestion: React.FC<props> = ({ input, setAnswers, answers, question, setWarning, currentLanguage }) => {

    const { t } = useTranslation();
    const [emissionDataList, setEmissionDataList] = useState<emissionData[]>(() => {
        return answers.some(item => (item.inputId == input.id) && (item.questionId == question.id)) ?
            answers.filter(item => (item.inputId == input.id) && (item.questionId == question.id)).map((item, index) => {
                let e: emissionData = {
                    id: index,
                    value: item.quantity ? item.quantity.toString() : "",
                    value2: item.quantity2 ? item.quantity2.toString() : "",
                    isCompleted: true
                }
                return e;
            }) : []
    })
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {

        if (emissionDataList.length == 0) addEmissionData()

        setInitialized(true);
    }, []);

    useEffect(() => {

        const list = answers.filter(item => (item.inputId != input.id) || item.questionId != question.id)
        const newList = emissionDataList.filter(item => item.value !== "").map(item => {
            const e: InputAnswer = {
                inputId: input.id,
                questionId: question.id,
                yesOrNoAnswer: false,
                quantity: parseInt(item.value),
                quantity2: parseInt(item.value2),
                uom: "KgCO2e"
            }
            return e
        })

        const updatedList = [...list, ...newList]
        setAnswers(updatedList)


    }, [emissionDataList]);

    const updateEmissionData = (index: number, value: string, value2: string, edit: boolean): void => {


        const updatedData = emissionDataList.map(data => {
            if (data.id === index) {
                return { ...data, id: index, value: value, value2: value2, isCompleted: edit };
            }
            return data;
        });
        setEmissionDataList(updatedData);

    };

    const addEmissionData = () => {

        if (emissionDataList.length == 0 || emissionDataList[emissionDataList.length - 1].value !== "") {
            const newId = emissionDataList.length;
            const emptyData: emissionData = {
                id: newId,
                value: "",
                value2: "",
                isCompleted: false
            };

            setEmissionDataList([...emissionDataList, emptyData]);
        }
        else {
            setWarning(t("warning5"))
        }


    };

    const handleDeleteLastRow = (index: number) => {
        if (emissionDataList.length > 0 && index == emissionDataList.length - 1) {
            const updatedList = emissionDataList.slice(0, -1);
            setEmissionDataList(updatedList);
        }
    };


    if (!initialized) {
        return null;
    }

    return (
        <Grid container
            spacing={2}
            direction="column"
            display='flex'
            alignItems="center"
            marginTop={'3vh'}
            marginBottom={'1vh'}
            paddingBottom={'1vh'}
            justifyContent={'center'}

        >
            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", border: '3px solid #007CC1', padding: "0.5rem", marginTop: "-1vh" }}>
                {t("scope2Option3")}
            </p>
            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", textAlign: "center", marginTop: "-1vh", marginBottom: "1vh" }}>
                {t("scope2Option32")}
            </p>

            {emissionDataList.map((data, index) => (

                <div key={index} style={{ marginBottom: '1rem', marginTop: "2vh" }}>
                    <EmissionComponent index={index} updateEmissionData={updateEmissionData} valuea={data.value} valueb={data.value2} handleDeleteLastRow={handleDeleteLastRow} setWarning={setWarning} size={emissionDataList.length} edit1={data.isCompleted} />
                </div>
            ))}


            <Box style={{ backgroundColor: "#007CC1", borderRadius: "100px", display: "flex", padding: "0.5rem", cursor: "pointer", transition: "background-color 0.3s" }} onClick={addEmissionData}>
                <AddIcon style={{ color: 'white', justifyContent: "center", fontSize: "1.2vw" }}></AddIcon>
            </Box>
        </Grid>
    )
}

export default EmissionQuestion