import React, { useEffect, useState } from "react";
import { Grid, Button, Divider, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputAnswer, Question } from "../../Integration/models";
import { CustomWidthTooltip } from "../../styles/Customtooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface props {

    question: Question | undefined
    setPertinentFlag: React.Dispatch<React.SetStateAction<string>>;
    pertinentFlag: string;
    setRelevantFlag: React.Dispatch<React.SetStateAction<string>>;
    relevantFlag: string
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    answers: InputAnswer[]
    scope: string
    currentLanguage: string,
    viewFlag: string;
    scopeTitle: boolean;
    scopeType: string;
    setWarning: React.Dispatch<React.SetStateAction<string>>

}

const Preliminary: React.FC<props> = ({ question, pertinentFlag, setPertinentFlag, relevantFlag, setRelevantFlag, setAnswers, answers, scope, currentLanguage, viewFlag, scopeTitle, scopeType, setWarning }) => {

    const { i18n, t } = useTranslation();
    const input = question?.inputs?.find(item => item)

    const handleValueChange = (option: string) => {

        const flag = (option == "SI") ? true : false

        if (!flag && question?.questionType == 1 && (scopeType === "S1" || scopeType === "M1" || scopeType === "E1")) setWarning(t("warning16"))

        const e: InputAnswer = {
            inputId: input?.id,
            questionId: question?.id,
            isRelevant: question?.questionType == 1 ? flag : undefined,
            isPertinent: question?.questionType == 0 ? flag : undefined,
            yesOrNoAnswer: question?.questionType == 1 ? flag : undefined,
        };

        if (question?.questionType == 0) {
            const ans = answers.filter(item => item.isGreen)
            setAnswers([...ans, e])
            setPertinentFlag(option)
            setRelevantFlag("")
        }
        else {
            const ans = answers.filter(item => (item.isPertinent != undefined && item.isPertinent != null) || item.isGreen)
            setAnswers([...ans, e])
            setRelevantFlag(option)
        }

    }

    return (
        <Paper
            square
            elevation={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 2,
                bgcolor: 'white',
                paddingBottom: '1rem',
            }}
        >
            {question?.questionType == 0 && (<>
                {scopeTitle && (<>
                    <p style={{ color: "#007CC1", fontSize: "1vw", fontWeight: 'bold' }}>{t("emissions")} {scope}</p>
                    <Divider style={{ width: "100%", color: "black" }} />
                </>)}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "-0.5vh", justifyContent: "center", padding: "0 10%", gap: "0.2vw" }}>
                    {/* <CustomWidthTooltip title={currentLanguage === "en" ? question?.explanationEng : question?.explanationIta} style={{ verticalAlign: "top", width: "0.1vw", height: "3vh" }}>
                        <Button variant="info" style={{ marginTop: "1vh" }}>
                            <InfoOutlinedIcon style={{ width: "0.8vw" }} />
                        </Button>
                    </CustomWidthTooltip> */}
                    <h2 style={{ color: "black", fontSize: "1vw", textAlign: "center", lineHeight: "1.3" }}>
                        <span>
                            <CustomWidthTooltip title={currentLanguage === "en" ? question?.explanationEng : question?.explanationIta} style={{ width: "0.1vw" }}>
                                <Button variant="info" style={{ marginBottom: '0.5vw', padding: 0, height: '1vw' }}>
                                    <InfoOutlinedIcon style={{ width: "1.2vw" }} />
                                </Button>
                            </CustomWidthTooltip>
                        </span>
                        {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
                    </h2>

                </div>

            </>)}

            {question?.questionType == 1 && (<>
                <h2 style={{ color: "black", fontSize: "1vw", marginTop: "2vh", textAlign: "center", width: "80%", lineHeight: "1.3" }}>
                    {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
                </h2>
            </>)}

            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", marginTop: "-2vh" }} >{currentLanguage === "en" ? question?.subtitleEng : question?.subtitleIta}</p>
            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", marginTop: "-3vh" }} >{currentLanguage === "en" ? question?.exampleEng : question?.exampleIta}</p>
            <Grid
                container
                spacing={4}
                direction="row"
                display='flex'
                justifyContent="center"
                alignItems="center"
                gap="0.5vw"
                marginTop="0.5vh"
                marginLeft="0.5vw"
            >
                <Button
                    variant='navigation'
                    onClick={() => handleValueChange("SI")}
                    style={{
                        color: viewFlag === "SI" ? 'white' : '#007CC1',
                        backgroundColor: viewFlag === "SI" ? '#007CC1' : 'white'
                    }}
                >
                    {t("yes")}
                </Button>
                <Button
                    variant='navigation'
                    onClick={() => handleValueChange("NO")}
                    style={{
                        color: viewFlag === "NO" ? 'white' : '#007CC1',
                        backgroundColor: viewFlag === "NO" ? '#007CC1' : 'white'
                    }}
                >
                    NO
                </Button>
            </Grid>

        </Paper>
    )
}

export default Preliminary