import { PaletteColor, PaletteColorOptions, Theme, createTheme } from '@mui/material/styles';


declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: React.CSSProperties['color'];
        };
    }

    interface ThemeOptions {
        status: {
            danger: React.CSSProperties['color'];
        };
    }

    interface Palette {

        carboncalc: PaletteColor;
        buttonadd: PaletteColor;

    }

    interface PaletteOptions {

        carboncalc: PaletteColorOptions;
        buttonadd?: PaletteColorOptions;
    }

    interface PaletteColor {
        darker?: string;
    }

    interface SimplePaletteColorOptions {
        darker?: string;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        navigation: true;
        info: true;
        selection: true
    }
}



export function createMyTheme(): Theme {
    return createTheme({


        status: {
            danger: '#e53e3e',
        },
        components: {
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'navigation' },
                        style: {
                            color: "white", // Colore del testo grigio scuro
                            borderColor: "#007CC1", // Colore del bordo grigio scuro
                            border: '2px solid #007CC1',
                            fontWeight: 'bold',
                            background: '#007CC1',
                            padding: "4px 8px",
                            fontSize: "0.9vw",
                            height: "4vh",
                            '&:hover': {
                                background: 'rgba(0, 124, 193, 0.8)', // Opacità del 80% per un background più opaco durante l'hover
                                color: "white", // Colore del testo grigio scuro durante l'hover
                                borderColor: "#007CC1", // Colore del bordo grigio scuro durante l'hover
                            },


                        }
                    },

                    {
                        props: { variant: 'selection' },
                        style: {
                            color: "#015F9F",
                            borderColor: "white",
                            fontWeight: 'bold',
                            background: '#FFD600',
                            padding: "4px 8px", // Mantieni il valore del padding
                            fontSize: "1vw", // Mantieni la dimensione del testo
                            height: "4vh", // Riduci l'altezza del bottone F

                        },


                    },

                    {
                        props: { variant: 'info' },
                        style: {
                            color: "black",
                            borderColor: "black",
                            fontWeight: 'bold',

                        },


                    },

                ],
            },


        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85',
            },
            carboncalc: {
                main: 'white',
                contrastText: '#fff',
            },
            buttonadd: {
                main: '#FFD600',
                contrastText: '#fff',
            },

        },


    });
}