import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { ThemeProvider } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid } from '@mui/material';
import num1 from "../../images/logos/num1.png";
import Init1 from "../../images/logos/init1.png";

interface props {

    titleEng: string,
    titleIta: string,
    actionEng: string,
    actionIta: string;
    currentLanguage: string;
    sfondo: string

}

export const ActionComponent: React.FC<props> = ({ titleEng,titleIta, actionEng, actionIta, currentLanguage, sfondo }) => {

    console.log(sfondo)


    return (
        <Grid container  style={{ width: '95%', background: sfondo, borderRadius: '15px', padding: '0.5rem', flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} spacing={2} >
            <Grid item style={{marginTop:"-2vh"}}>
            </Grid>
            <Grid item xs={1} style={{marginTop:"-2vh"}}>
                <img src={Init1} alt="1" style={{ display: 'flex', alignSelf: 'flex-start' }} />
            </Grid>
            <Grid item xs={9} style={{marginTop:"-2vh"}}>
                <Box>
                    <p style={{ color: "white", fontWeight: 'bold', textAlign: 'left', fontSize: '1.2vw' }}>{currentLanguage === "en" ? titleEng : titleIta}</p>
                    <p style={{ color: "white", textAlign: 'left', fontSize: '1vw' }}>{currentLanguage === "en" ? actionEng : actionIta}</p>
                </Box>
            </Grid>
            <Grid item xs={1} style={{marginTop:"-2vh"}}></Grid>
        </Grid>
    )
}