import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';


export const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input': {
      color: 'black',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#007CC1',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#007CC1',
    },
    '& .MuiInput-underline:hover:after': {
      borderBottomColor: '#007CC1',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#007CC1',
    },
    
  });
  
  
  
  
  
  
  
  