import React from 'react'
import { useTranslation } from "react-i18next";
import { Input, InputAnswer, Question } from "../../Integration/models";
import { CustomTextField } from '../../styles/CustomTextField';
import { Button } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';

interface props {

    
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    index: number;
    updateEmissionData: (index: number, value: string, value2: string, edit1:boolean) => void;
    handleDeleteLastRow: (index: number) => void,
    edit1: boolean
    size: number;
    valuea: string;
    valueb: string

}

const EmissionComponent: React.FC<props> = ({ index, setWarning, updateEmissionData, handleDeleteLastRow, size, valuea, valueb, edit1 }) => {

    const { t } = useTranslation();
    const [value1, setValue1] = React.useState<string>(valuea)
    const [value2, setValue2] = React.useState<string>(valueb)
    const [edit, setEdit] = React.useState<boolean>(!edit1);

    const handleEditChange = (event: React.SyntheticEvent<unknown>) => {
        if (edit) {
            if (value1 === "" || value2 === "") setWarning(t("warning6"))
            else if (isNaN(parseInt(value1)) || isNaN(parseInt(value2))) setWarning(t("warning3"))
            else {
                updateEmissionData(index, value1, value2,true)
                setEdit(false)
            }
        }
        else {
            updateEmissionData(index, "", "",false)
            setEdit(true)
        }

    }

    const handleDeleteChange = (event: React.SyntheticEvent<unknown>) => {

        if (index == 0 || index < size - 1) {
            updateEmissionData(index, "", "",false)
            setValue1("")
            setValue2("")
        }
        else {
            handleDeleteLastRow(index)
        }

        setEdit(true)
    }

    return (
        <>

            <div style={{ display: "flex", alignItems: "center", gap: "3vw",justifyContent: "center" }}>

                <CustomTextField
                    autoComplete="off"
                    disabled={!edit}
                    FormHelperTextProps={{ style: { color: "black" } }}
                    InputLabelProps={{
                        style: { color: "black" }
                    }}
                    onChange={(e) => setValue1(e.target.value)}
                    name="Name"
                    variant="standard"
                    label={t("value")}
                    defaultValue={value1}
                    value={value1}
                    style={{ width: "10vw" }}

                />

                <p style={{ color: "black", fontSize: "0.8vw", textAlign: "center", marginBottom: "-1vh", width: "5vw" }}>KgCO2e/kWh​</p>

                <CustomTextField
                    autoComplete="off"
                    disabled={!edit}
                    FormHelperTextProps={{ style: { color: "black" } }}
                    InputLabelProps={{
                        style: { color: "black" }
                    }}
                    onChange={(e) => setValue2(e.target.value)}
                    name="Name"
                    variant="standard"
                    label={t("value")}
                    defaultValue={value2}
                    value={value2}
                    style={{ width: "10vw", marginLeft: "2vw" }}

                />

                <p style={{ color: "black", fontSize: "0.8vw", textAlign: "center", marginBottom: "-1vh", width: "2vw" }}>kWh​</p>
                {edit && (
                    <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                        <Button onClick={(e) => handleEditChange(e)}
                            variant="navigation"
                        ><DoneOutlineSharpIcon /></Button>
                        <Button onClick={(e) => handleDeleteChange(e)}
                            variant="navigation"
                        ><DeleteOutlineOutlinedIcon /></Button>
                    </div>
                )
                }
                {!edit && (
                    <div style={{ display: "flex", flexDirection: "row", gap: "1vw", marginBottom: "-2vh" }}>
                        <Button onClick={(e) => handleEditChange(e)}
                            variant="navigation"
                        ><CreateSharpIcon /></Button>
                        <Button onClick={(e) => handleDeleteChange(e)}
                            variant="navigation"
                        ><DeleteOutlineOutlinedIcon /></Button>
                    </div>
                )}
            </div>
        </>
    )
}


export default EmissionComponent