import { Divider, Grid } from '@mui/material';
import React from 'react';
import PieChart from '../Grafici/PieChart';
import { BorderLinearProgress2 } from '../../styles/LinearProgress';

interface props {
    percentage: string ; // Percentuale da rappresentare
    type: string;

}

const ChartItem2: React.FC<props> = ({ percentage, type }) => {


    const flag = percentage === "NA"


    return (
        <Grid container style={{ gap: "1vw" }}>
            <Grid item xs={3} sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center" }}>
                <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{type}</p>
            </Grid>
            <Grid item xs={8} sx={{ border: "2px solid lightblue", borderRadius: "10px", padding: "0.5rem", textAlign: "center", alignItems: "center" }}>
                <Grid container alignItems="center" justifyContent="flex-start" marginTop="0.5vh">
                    {
                        flag ? (<>
                            <Grid item xs={3}>
                                <p style={{ fontSize: "1.1vw" }}><strong>{percentage}</strong></p>
                            </Grid>
                            <Grid item xs={8}>
                                <BorderLinearProgress2 variant="determinate" value={0} />
                            </Grid>
                        </>) : (<>
                            <Grid item xs={3}>
                                <p style={{ fontSize: "1.1vw" }}><strong>{percentage} %</strong></p>
                            </Grid>
                            <Grid item xs={8}>
                                <BorderLinearProgress2 variant="determinate" value={parseInt(percentage)} />
                            </Grid>
                        </>)
                    }
                </Grid>
            </Grid>
        </Grid>

    )

}

export default ChartItem2;

