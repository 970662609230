
import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createMyTheme } from './styles/theme';
import { Profile } from './Pages/Welcome/Profile';
import { Menu } from './Pages/Welcome/Menu';
import "./i18n";
import { Final } from './Pages/Report/Final';
import { ReportComponent } from './Pages/Report/Report';
import { Main } from './Pages/Components/Main';
import { ClusterQuestion } from './Pages/Welcome/ClusterQuestion';
import S1 from './Pages/Scope1/S1';
import M1 from './Pages/Scope1/M1';
import F1 from './Pages/Scope1/F1';
import R1 from './Pages/Scope1/R1';
import E1 from './Pages/Scope2/E1';
import E2 from './Pages/Scope2/E2';
import * as client from './Integration/api';
import { Configuration } from './Integration';
import { Login } from './Login';
import { ModalComponent } from './Pages/Components/ModalComponent';
import { useParams } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_USER } from "./costants";
import { Footer } from './Pages/Welcome/Footer';
import { Card2 } from './Pages/Welcome/Card2';
import { Assistenza } from './Pages/Welcome/Assistenza';
//http://34.149.246.28/?token=YKCkj0xk35SS0TX5flX7Ge48qCZfG3
//OKVFOLiQUxmwpufAYOroE68Aime3tRViewer
function App() {

  const theme = createMyTheme();
  const [settore, setSettore] = React.useState<string>("")
  const [warning, setWarning] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [token, setToken] = React.useState<string | undefined>(() => {
    const res = sessionStorage.getItem('sessionToken');
    return res ? res : undefined
  });
  const [completedSteps, setCompletedSteps] = React.useState<number>(() => {
    const res = sessionStorage.getItem('completedSteps');
    return res ? parseInt(res) : 0
  });
  const [role, setRole] = React.useState<string | undefined>(() => {
    const res = sessionStorage.getItem('role');
    return res ? res : undefined
  });

  useEffect(() => {
    if (warning !== "")
      setOpen(true)
  }, [warning]);


  useEffect(() => {
    if (completedSteps) {
      sessionStorage.setItem("completedSteps", completedSteps.toString())
    }
  }, [completedSteps]);


  return (

    <ThemeProvider theme={theme}>
      {/* <div style={{height:"calc(100vh - 7vh - 12vh)", overflow:"auto"}}> */}
      <Router>
        <Menu></Menu>
        <ModalComponent open={open} setOpen={setOpen} warning={warning} setWarning={setWarning} />
        <Routes>
          <Route path="/" element={<Login setToken={setToken} setWarning={setWarning} setRole={setRole} />} />
          {token && role === ROLE_ADMIN && (<>
            <Route path="/welcome" element={<Card2 />} />
            <Route path="/assistenza" element={<Assistenza />} />
            <Route path="/profile" element={<Profile setWarning={setWarning} setSettore={setSettore} />} />
            <Route path="/cluster" element={<ClusterQuestion />} />
            <Route path="/scope1-S1" element={<S1 settore={settore} completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
            <Route path="/scope1-M1" element={<M1 completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
            <Route path="/scope1-F1" element={<F1 completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
            <Route path="/scope1-R1" element={<R1 completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
            <Route path="/scope2-E1" element={<E1 completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
            <Route path="/scope2-E2" element={<E2 completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
            <Route path="/final" element={<Final setWarning={setWarning} />} />
            <Route path="/report" element={<ReportComponent setWarning={setWarning} completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
          </>)
          }
          {token && role === ROLE_USER && (<>
            <Route path="/profile" element={<Profile setWarning={setWarning} setSettore={setSettore} />} />
            <Route path="/report" element={<ReportComponent setWarning={setWarning} completedSteps={completedSteps} setCompletedSteps={setCompletedSteps} />} />
          </>)
          }
        </Routes>
      </Router>

      <Footer></Footer>
    </ThemeProvider >

  );
}

export default App;
