
import { Alert, AlertTitle, Box, Collapse, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'

interface props {
    setWarning: React.Dispatch<React.SetStateAction<string>>,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    warning: string
    open: boolean
}

export const ModalComponent: React.FC<props> = ({warning, open, setWarning, setOpen}) => {

    return (<>

        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "30vw",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <Collapse in={open}>
                    <Alert
                        severity="warning"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                    setWarning("")
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Warning</AlertTitle>
                        {warning}
                    </Alert>
                </Collapse>
            </Box>
        </Modal>

    </>
    )

}