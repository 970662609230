import React from "react";
import { Input, InputAnswer, Question } from "../../Integration/models";
import FuelQuestion from "../Questions/FuelQuestion";
import { Button, Divider, Paper } from "@mui/material";
import { CustomWidthTooltip } from "../../styles/Customtooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";

interface props {
    inputs: Input[] | undefined
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    answers: InputAnswer[]
    question: Question
    currentLanguage: string
    scopeType:string
}

const FuelQuestionController: React.FC<props> = ({ inputs, setAnswers, answers, question, setWarning, currentLanguage, scopeType }) => {

    const { t } = useTranslation();

    return (<>
        <Paper
            square
            elevation={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 2,
                bgcolor: 'white',
                gap: "1vh",
                paddingBottom:"1vh"
            }}
        >
           
                <h2 style={{ color: "black", fontSize: "1vw", textAlign:"center", width:"80%" }}>
                    {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
                </h2>
           
            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", marginTop: "-4vh", alignContent: "center" }} >{currentLanguage === "en" ? question?.subtitleEng : question?.subtitleIta}</p>
            {inputs?.map((data, index) => (<>
                <Divider style={{ width: "100%", color: "black" }} />
                <FuelQuestion key={data.id} input={data} setAnswers={setAnswers} answers={answers} question={question} setWarning={setWarning} currentLanguage={currentLanguage} scopeType={scopeType}/>
            </>
            ))
            }
        </Paper >
    </>
    )
}

export default FuelQuestionController